import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Axios } from './components/Axios';
import Barcode from 'react-jsbarcode';
import './App.css';
import moment from 'moment';

function App() {
  const [orderData, setOrderData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const [order] = useSearchParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const getOrderData = () => {
    setPageLoading(true);
    Axios({
      method: "GET",
      url: `orders/get_order_by_id_for_admin/${order.get("id")}`,
    })
      .then((res) => {
        setOrderData(res?.result);
        setValue(
          res?.result?.orderproducts?.reduce(
            (acc, curr) => acc + parseFloat(curr?.product_price) * parseFloat(curr?.product_count),
            0
          )
        );
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getOrderData();
  }, [order]);

  useEffect(() => {
    if (orderData?.order_value) {
      setTimeout(() => {
        window.print();
        // navigate(-1);
      }, 2000);
    }
  }, [orderData]);

  return (
    orderData?.order_value && (
      <div className="App">
        {order.get("lang") == "ar" ? (
          <>
            <nav>
              <h1>فاتورة</h1>
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1710981073/24e1cbe7d0f72e8cee116ee85720cd86_ranfgv.png"
                alt=""
              />
            </nav>

            <div className="invoice-data">
              <div className="invoice_data_shop_data">
                <p>alseedawi Food Industries</p>
                <p>Shuwaikh Industrial</p>
                <p>Phone Number: 0096594957686</p>
                <p>export@alseedawisweets.com</p>
                <p>https://alseedawionine.com</p>
              </div>
              <div className="invoice_data_order_info">
                <span>
                  <p>تاريخ الانشاء:</p>
                  <p>{moment(orderData?.order_time).format("L")}</p>
                </span>
                <span>
                  <p>وقت الانشاء :</p>
                  <p>{moment(orderData?.created_at).format("HH:mm:ss")}</p>
                </span>
                <span>
                  <p>مرجع العميل :</p>
                  <p>{orderData?.id}</p>
                </span>
                <span>
                  <p>رقم الفاتورة :</p>
                  <p>{orderData?.invoiceId}</p>
                </span>
                <span>
                  <p>مرجع الفاتورة :</p>
                  <p>{orderData?.paymentId}</p>
                </span>
                <span>
                  <p>طريقة الدفع :</p>
                  <p>{orderData?.pay_type}</p>
                </span>
                <span>
                  <p>رسوم الشحن :</p>
                  <p>
                    {value < 10 ? "1.5" : "0"} د.ك
                  </p>
                </span>
              </div>
            </div>

            <table>
              <tr>
                <th>فاتورة </th>
                <th>يتم شحنها إلى</th>
              </tr>
              <tr>
                <th className="data_container">
                  <span>
                    <p>مرجع العميل :</p>
                    <p>{orderData?.id}</p>
                  </span>
                  <span>
                    <p>اسم العميل :</p>
                    <p>{orderData?.user?.name}</p>
                  </span>
                  <span>
                    <p>رقم تليفون العميل :</p>
                    <p>{orderData?.user?.phone}</p>
                  </span>
                  <span>
                    <p>البريد الالكتروني للعميل :</p>
                    <p>{orderData?.user?.email}</p>
                  </span>
                </th>
                <th className="address">
                  {/* {
              {
    "name": "",
    "phone": "",
    "city": "قطعة",
    "streat": "شارع",
    "architecture": "منزل ",
    "floor": "دور",
    "apartment": " شقة",
    "special_marque": "",
    "location": "",
    "status": 0,
    "region": 65,
    "district": "جادة"
}
            } */}
                  <span>
                    <p>المنطقة :</p>
                    <p>{orderData?.location_data?.region}</p>
                  </span>
                  <span>
                    <p>القطعة :</p>
                    <p>{orderData?.location_data?.city}</p>
                  </span>
                  <span>
                    <p>الجادة :</p>
                    <p>{orderData?.location_data?.district}</p>
                  </span>
                  <span>
                    <p>الشارع :</p>
                    <p>{orderData?.location_data?.streat}</p>
                  </span>
                  <span>
                    <p>المنزل :</p>
                    <p>{orderData?.location_data?.architecture}</p>
                  </span>
                  <span>
                    <p>الدور :</p>
                    <p>{orderData?.location_data?.floor}</p>
                  </span>
                  <span>
                    <p>الشقة :</p>
                    <p>{orderData?.location_data?.apartment}</p>
                  </span>
                </th>
              </tr>
            </table>
            <table className="table2">
              <tr>
                <th className="product" style={{ textAlign: 'left' }}>
                  صورة المنتج
                </th>
                <th className="product_model">اسم المنتج</th>
                <th className="product_barcode">الباركود</th>
                <th className="product_qut">الكمية</th>
                <th className="product_price">السعر</th>
                <th className="product_total">السعر الكلي</th>
              </tr>
              {orderData?.orderproducts?.map((product, index) => (
                <tr key={index}>
                  <th className="product">
                    <img src={product.product.images[0]?.url} alt="" />
                  </th>
                  <th className="product_model">{product.product.title_ar}</th>
                  <th className="product_barcode">
                    <div
                      style={{
                        width: '200px',
                        scale: 0.1,
                        whiteSpace: 'wrap',
                      }}
                    >
                      <Barcode
                        className="pharmcode"
                        value={`${product?.product?.Barcode}`}
                      />
                    </div>
                  </th>
                  <th className="product_qut">{product.product_count}</th>
                  <th className="product_price">
                    {product?.product_price &&
                      parseFloat(product?.product_price)?.toFixed(3)}{" "}
                    دينار كويتي
                  </th>
                  <th className="product_total">
                    {(
                      parseFloat(product.product_price) *
                      parseInt(product.product_count)
                    ).toFixed(3)}{" "}
                    دينار كويتي
                  </th>
                </tr>
              ))}
              <tr>
                <th colSpan="6">رسوم الشحن</th>
                <th>
                  {value < 10 ? "1.5" : "0"} دينار
                  كويتي
                </th>
              </tr>
              <tr>
                <th colSpan="6">sub-total</th>
                <th>
                  {/* {alert(value)} */}
                  {orderData?.order_value
                    ? value < 10
                      ? (parseFloat(orderData.order_value) - 1.5).toFixed(3)
                      : parseFloat(orderData.order_value).toFixed(3)
                    : 0}
                  دينار كويتي
                </th>
              </tr>
              <tr>
                <th colSpan="6">total</th>
                <th>
                  {orderData?.order_value &&
                    parseFloat(orderData?.order_value)?.toFixed(3)}{" "}
                  دينار كويتي
                </th>
              </tr>
            </table>
          </>
        ) : (
          <>
            <nav style={{ borderTop: '.1px solid black', marginTop: '50px' }}>
              <h1>Invoice</h1>
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1710981073/24e1cbe7d0f72e8cee116ee85720cd86_ranfgv.png"
                alt=""
              />
            </nav>

            <div className="invoice-data">
              <div className="invoice_data_shop_data">
                <p>alseedawi Food Industries</p>
                <p>Shuwaikh Industrial</p>
                <p>Phone Number: 0096594957686</p>
                <p>export@alseedawisweets.com</p>
                <p>https://alseedawionine.com</p>
              </div>
              <div className="invoice_data_order_info">
                <span>
                  <p>date added:</p>
                  <p>{moment(orderData?.order_time).format("L")}</p>
                </span>
                <span>
                  <p>time added :</p>
                  <p>{moment(orderData?.created_at).format("HH:mm:ss")}</p>
                </span>
                <span>
                  <p>Customer Reference :</p>
                  <p>{orderData?.id}</p>
                </span>
                <span style={{ fontSize: "14px " }}>
                  <p>Invoice Number :</p>
                  <p>{orderData?.invoiceId}</p>
                </span>
                <span style={{ fontSize: "14px " }}>
                  <p>Invoice Refernce :</p>
                  <p>{orderData?.paymentId}</p>
                </span>
                <span>
                  <p>payment method :</p>
                  <p>{orderData?.pay_type}</p>
                </span>
                <span>
                  <p>shipping price : </p>
                  <p>
                    {value < 10 ? "1.5" : "0"} KWD{" "}
                  </p>
                </span>
              </div>
            </div>

            <table>
              <tr>
                <th>invoice</th>
                <th>ship for (if different address)</th>
              </tr>
              <tr>
                <th className="data_container">
                  <span>
                    <p>customer Reference:</p>
                    <p>{orderData?.id}</p>
                  </span>
                  <span>
                    <p>customer Name:</p>
                    <p>{orderData?.user?.name}</p>
                  </span>
                  <span>
                    <p>customer number :</p>
                    <p>{orderData?.user?.phone}</p>
                  </span>
                  <span>
                    <p>customer E-mail :</p>
                    <p>{orderData?.user?.email}</p>
                  </span>
                </th>
                <th className="address">
                  <span>
                    <p>Area:</p>
                    <p>{orderData?.location_data?.region_data?.title_en}</p>
                  </span>
                  <span>
                    <p>block:</p>
                    <p>{orderData?.location_data?.city}</p>
                  </span>
                  <span>
                    <p>Revenue :</p>
                    <p>{orderData?.location_data?.district}</p>
                  </span>
                  <span>
                    <p>Street:</p>
                    <p>{orderData?.location_data?.streat}</p>
                  </span>
                  <span>
                    <p>Home:</p>
                    <p>{orderData?.location_data?.architecture}</p>
                  </span>
                  <span>
                    <p>Floor:</p>
                    <p>{orderData?.location_data?.floor}</p>
                  </span>
                  <span>
                    <p>The appartment:</p>
                    <p>{orderData?.location_data?.apartment}</p>
                  </span>
                </th>
              </tr>
            </table>

            <table className="table2">
              <tr>
                <th className="product" style={{ textAlign: 'left' }}>
                  Image
                </th>
                <th className="product_model">Name</th>
                <th className="product_barcode">Barcode</th>
                <th className="product_qut">QTY</th>
                <th className="product_price">Price</th>
                <th className="product_total">Total</th>
              </tr>
              {orderData?.orderproducts?.map((product, index) => (
                <tr key={index}>
                  <th className="product">
                    <img src={product.product.images[0]?.url} alt="" />
                  </th>
                  <th className="product_model">{product.product.title_en}</th>
                  <th className="product_barcode">
                    <Barcode
                      className="pharmcode"
                      style={{ height: "90px" }}
                      value={`${product?.product?.Barcode}`}
                    />
                  </th>
                  <th className="product_qut">{product.product_count}</th>
                  <th className="product_price">
                    {product?.product_price &&
                      parseFloat(product?.product_price)?.toFixed(3)}{" "}
                    KWD
                  </th>
                  <th className="product_total">
                    {(
                      parseFloat(product.product_price) *
                      parseInt(product.product_count)
                    ).toFixed(3)}{" "}
                    KWD
                  </th>
                </tr>
              ))}
              <tr>
                <th colSpan="6">Shiping</th>
                <th>
                  {value < 10 ? "1.5" : "0"}
                  KWD
                </th>
              </tr>
              <tr>
                <th colSpan="6">Sub-Total</th>
                <th>
                  {orderData?.order_value
                    ? value < 10
                      ? (parseFloat(orderData.order_value) - 1.5).toFixed(3)
                      : parseFloat(orderData.order_value).toFixed(3)
                    : 0}
                  KWD
                </th>
              </tr>
              <tr>
                <th colSpan="6">total</th>
                <th>
                  {console.log("order", order)}
                  {(orderData?.order_value + 0)?.toFixed(3)} KWD
                </th>
              </tr>
            </table>
          </>
        )}
      </div>
    )
  );
}

export default App;
